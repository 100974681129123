import {Datagrid, ExportButton, FilterButton, List, TextField, TextInput, TopToolbar} from 'react-admin';

const filters = [<TextInput label='Название' source='title' />];

const ListActions = () => (
    <TopToolbar>
        <FilterButton />
        <ExportButton />
    </TopToolbar>
);

export default () => {
    return (
        <List actions={<ListActions />} filters={filters} sort={{field: 'id', order: 'desc'}} emptyWhileLoading>
            <Datagrid rowClick='edit'>
                <TextField label='№' source='id' />
                <TextField label='Название' source='title' />
            </Datagrid>
        </List>
    );
};
