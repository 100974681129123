import {
    Edit,
    FileField,
    FileInput,
    Labeled,
    SaveButton,
    TabbedForm,
    TextInput,
    Toolbar,
    useRecordContext
} from 'react-admin';
import React from 'react';
import {Stack} from '@mui/material';

const Form = () => {
    const record = useRecordContext();

    return (
        <>
            <TextInput label='№' source='id' fullWidth disabled />
            <TextInput label='Название' source='title' required fullWidth />

            {record?.completeCoverFile?.url && (
                <Labeled label={'Изображение (достижение получено)'} sx={{marginBottom: 3}}>
                    <Stack gap={2} direction='row' alignItems='flex-start'>
                        <img src={record?.completeCoverFile?.url} width={200} height={200} />
                    </Stack>
                </Labeled>
            )}
            <FileInput
                source='file1'
                label='Новое изображение (достижение получено)'
                maxSize={20000000}
                accept='image/*'>
                <FileField source='src' title='title' />
            </FileInput>

            {record?.incompleteCoverFile?.url && (
                <Labeled label={'Изображение (достижение не получено)'} sx={{marginBottom: 3}}>
                    <Stack gap={2} direction='row' alignItems='flex-start'>
                        <img src={record?.incompleteCoverFile?.url} width={200} height={200} />
                    </Stack>
                </Labeled>
            )}
            <FileInput
                source='file2'
                label='Новое изображение (достижение не получено)'
                maxSize={20000000}
                accept='image/*'>
                <FileField source='src' title='title' />
            </FileInput>

            <TextInput
                label='Описание для подсказки'
                source='description'
                fullWidth
                multiline
                minRows={5}
                maxRows={5}
                required
            />
        </>
    );
};
export const AchievementEdit = () => {
    return (
        <Edit
            transform={(data) => ({
                ...data,
                newCompleteCoverFile: data?.file1,
                newIncompleteCoverFile: data?.file2,
                file1: undefined,
                file2: undefined
            })}>
            <TabbedForm
                toolbar={
                    <Toolbar>
                        <SaveButton />
                    </Toolbar>
                }>
                <TabbedForm.Tab label='Контент'>
                    <Form />
                </TabbedForm.Tab>
            </TabbedForm>
        </Edit>
    );
};
